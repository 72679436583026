import React, { Component, createRef, Fragment } from 'react';
import ContentHero from 'shared/cms/ContentHero';
import Template from 'components/Template';
import TicketApplication from 'shared/general/TicketApplication';
import AccountInfo from 'shared/general/AccountInfo';
import { getQuerystringValues } from 'appdir/components/general/Util';
import CmsMessageContent from 'shared/general/CmsMessageContent';
import axios from 'axios';
import MeasurementUtils from 'appdir/lib/analytics';
import jwt_decode from "jwt-decode";
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import LoadingIndicator from 'shared/general/LoadingIndicator';
import StepIndicator from 'shared/general/StepIndicator';
import Button from 'shared/general/Button';
import EventsLink from 'shared/general/EventsLink';
import cn from 'classnames';

import { getApplicationPhase, getPaymentSteps, isAddressEditable, fetchDataFromPaths} from 'shared/general/Util';

import { renderErrorItem } from 'shared/forms/elements/Utils';
import { replaceAll } from 'shared/general/Util';

import op from 'object-path';
import { Redirect } from 'react-router';
export default class PaymentContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			ticketType: 'Invoice',
			timeRemaining:null,
			fiservFormLoaded: false,
			errors: {
				payment: [],
			},
			success: false,
			type: null,
			showCvv: false,
			application: null,
			submitting: false
		};

		this.sessionId = '';
		this.parsedQs = getQuerystringValues();
		this.jwtTimer = null;
		this.refreshInterval = null;
		this.otherAppsLoaded = false;
		this.fiservFormLoaded = false;

		/** setup event listener for our window messages */
		this.receiveEventsMessage = this.receiveEventsMessage.bind(this);

		window.addEventListener('message', this.receiveEventsMessage, false);

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Payment',
			site: this.props.userData.eventName,
		});
		logger.log('[PaymentContent] - constructor this:%o', this);
		// const script = document.createElement('script');
		// script.src = this?.props?.paymentConfig?.paymentType == 'cre' ? "/assets/static/payment/payment.js" :this?.props?.paymentConfig?.fiserv_sdk;
		// logger.log('[PaymentContent] - constructor script.src :%o', script.src);
		// logger.log('[PaymentContent] - constructor  :%o', this?.props?.paymentConfig?.paymentType);


		// document.head.appendChild(script);
	}

	componentDidMount() {
		// load the config web file here.
		this.props.loadConfig();
		this.fiservRef = React.createRef();
		this.setState({
			mounted: true,
			success: false,
			type: null,
			errors: {
				...this.state.errors,
			},
		});

	}

	componentWillUnmount() {
		if (this.jwtTimer) {
			clearInterval(this.jwtTimer);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		//logger.log('[PaymentContent] - componentDidUpdate this:%o', this);
		// is config web loaded?
		if (
			this.props.siteConfigStatus == 'loaded' &&
			this.props.staticDataStatus == 'loaded' &&
			this?.props?.eventConfigStatus == 'loaded' && 
			this.props.messageSettings &&
			this.props.flowData &&
			!this.state.isReady
		) {
			//logger.log('[PaymentContent] - componentDidUpdate set isReady:%o', this.state.isReady);

			this.setState(
				{
					isReady: true,
				},
				() => {

					axios({
						method: 'get',
						url: this.props.ticketsData.application,
						headers: { Authorization: `Bearer ${this.props.userData.token}` },
					})
					.then(applicationRes => {
						logger.log('[PaymentContent] applicationRes:%o', applicationRes);
						if (applicationRes.status == 200) {

							this.setState({
								application: {...applicationRes.data},
								success: false,
								type: null,
							}, () => {
								logger.log('[PaymentContent] applicationRes updateUserData:%o', this.props.userData);
								this.props.updateUserData({...this.props.userData, application: this.state.application}, true);
							});
						} else {
							this.setState({
								application: null,
								success: false,
								type: null,
								errors: {
									...this.state.errors,
									application: this?.props?.messageSettings['general.service_error']?.replace("{0}", "PAY1"),
								},
							});
						}
					})
					.catch(error => {
						logger.log('[PaymentContent] applicationRes error getting application error:%o', error);
						this.setState({
							success: false,
							type: null,
							errors: {
								...this.state.errors,
								application: this?.props?.messageSettings['general.service_error']?.replace("{0}", "PAY2"),
							},
						});
					})

					
					if (!this.otherAppsLoaded && this?.props?.ticketsData?.applicationUrlList) {
						this.otherAppsLoaded = true;
						fetchDataFromPaths(this?.props?.ticketsData?.applicationUrlList, this?.props?.userData?.token)
							.then((result) => {
									this.setState({
										applicationData: result,
										editable: isAddressEditable(this?.props?.userData, this?.props?.messageSettings, result)
									}, () => {
										//this.getFiservForm()
									})
							})
							.catch((error) => {
								logger.log('[PaymentContent] applicationRes error getting other applications error:%o', error);
								this.setState({
									success: false,
									type: null,
									errors: {
										...this.state.errors,
										application: this?.props?.messageSettings['general.service_error']?.replace("{0}", "PAY6"),
									},
								});
							})
					}
				}
			);
		}

		//logger.log('[PaymentContent] - componentDidUpdate jwtExpirationTime:%o', jwtExpirationTime);
		
		//logger.log('[PaymentContent] - componentDidUpdate countDownDate:%o', countDownDate);

		if (!this.jwtTimer && this.props.secureJwt !== prevProps.secureJwt) {
			logger.log(`[PaymentContent] - componentDidUpdate about to start timer secureJwt:%o`, this.props.userData.secureJwt);

			let decodedJwt = jwt_decode(this?.props?.userData?.secureJwt);
			let jwtExpirationTime = moment(decodedJwt.exp * 1000);
			let countDownDate = new Date(jwtExpirationTime).getTime();

		  	this.jwtTimer = setInterval(() => {

				// Get today's date and time
				let now = new Date().getTime();
			
				// Find the distance between now and the count down date
				let distance = countDownDate - now;
			
				// Time calculations for days, hours, minutes and seconds
				let days = (Math.floor(distance / (1000 * 60 * 60 * 24))).toString();
				let hours = (Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).toString();
				let minutes = (Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))).toString();
				let seconds = (Math.floor((distance % (1000 * 60)) / 1000)).toString();
				//logger.log(`[PaymentContent] - componentDidUpdate ${days}:${hours}:${minutes}:${seconds} `);

				this.setState({
					timeRemaining: {
						days,
						hours,
						minutes,
						seconds: seconds.length < 2 ? `0${seconds}` : seconds
					}
				})
				// If the count down is finished, write some text
				if (distance < 0) {
				clearInterval(this.jwtTimer);
				this.props.logout();
				logger.log(`[PaymentContent] - componentDidUpdate EXPIRED `);
				}
		    }, 1000);

			// if winner owes nothing, then just process the payment as zero
			if (this.state.application.amtDue > 0) {
				this.setState({
					iframeSrc: this.getIframeSrc(this.state.application),
					pInvoice: this?.props?.attributes?.createInvoice(false),
					tInvoice: this?.props?.attributes?.createInvoice(true),	
				}, () => {
					//this.getFiservForm()
				});
			} else {
			    this.handleSuccess();
			}
		}

		if (this.fiservRef.current && !this.fiservFormLoaded && this?.props?.userData?.token) {
			this.fiservFormLoaded = true;
				this.setState({
					formPromise: this.getFiservForm()
				}, () => {
					this?.state?.formPromise?.then(val => {
						val.configure({
							field: "nameOnCard",
							mode: "DISABLED",
							placeholder: {
								updateType: "TEXT",
								value: `${this?.props?.userData?.firstName} ${this?.props?.userData?.lastName}`
							}
						})
					})
				})
				logger.log(`[PaymentContent] - componentDidUpdate this.state.formPromise: %o`, this.state.formPromise);
		}
	}

	submitFiservForm() {
		logger.log('[PaymentContent] - submitFiservForm ');
        //console.log("jwt -> "+$("#jwt").val());

		this.setState({
			submitting: true
		}, () => {
			axios({
				method: 'get',
				url: this.props.ticketsData.fiserv_startPay,
				headers: { Authorization: `Bearer ${this.props.userData.token}` },
			})
			.then(startPayData => {
				logger.log("[PaymentContent] - submitFiservForm - startPayData: %o", startPayData?.data);
	
				this.state.formPromise.then((val) => {
					logger.log("[PaymentContent] - submitFiservForm - formPromise fullfilled: %o", val);
					const submitPromise = val.submit({
						apiKey: startPayData.data.apiKey,
						accessToken: startPayData.data.accessToken,
						createToken: false,
						publicKey: startPayData.data.publicKey,
						keyId: startPayData.data.keyId,
						merchantId: startPayData.data.merchantId,
						terminalId: startPayData.data.terminalId 
					});
	
					submitPromise.then((val) => {
						console.log("[PaymentContent] - submitFiservForm - submitPromise fullfilled: %o", val);
						
						axios({
							method: "get",
							url: this.props.ticketsData.fiserv_finishPay,
							headers: { Authorization: `Bearer ${this.props.userData.token}` },
							params: {sessionId: startPayData.data.sessionId}
						}).then(verifyPayData => {
							console.log("[PaymentContent] - submitFiservForm - verifyPayData: %o", verifyPayData);
							if (verifyPayData.status == '200') {
								if (verifyPayData?.data?.applicationStatus == 3) {
									this.setState(
										{
											success: true,
											submitting: false,
											type: 'payment',
											errors: {}
										},
										() => {
											logger.log('[PaymentContent] submitFiservForm after finishPay:%o', verifyPayData?.data);
			
											this.refreshInterval = setInterval(() => {
												if (this.props.userData?.application?.applicationStatus === 3) {
													// Stop the interval once the condition is met (applicationStatusId === 3).
													clearInterval(this.refreshInterval);
												  } else {
													// Call the refreshProfile function if the condition is not met.
													this.props.refreshProfile();
												  }
											}, 2000);
			
											//this.props.refreshProfile();
										}
									);
								} else {
									logger.log('[PaymentContent] handleSuccess could not validate payment');
									this.setState({
										success: false,
										submitting: false,
										type: null,
										errors: {
											...this.state.errors,
											response: this?.props?.messageSettings['general.service_error']?.replace("{0}", "PAY3"),
										},
									});
								}
							} else {
								logger.log('[PaymentContent] handleSuccess verifyPayData.status:%o', verifyPayData.status);
								//throw new Error(payRes.status);
								this.setState({
									success: false,
									submitting: false,
									type: null,
									errors: {
										...this.state.errors,
										response: this?.props?.messageSettings['general.service_error']?.replace("{0}", "PAY4"),
									},
								});
							}
							
						}).catch((error) => {
							logger.log('[PaymentContent] submitFiservForm finishPay error:%o', error);
							this.setState({
								success: false,
								submitting: false,
								type: null,
								errors: {
									...this.state.errors,
									response: this?.props?.messageSettings['general.service_error']?.replace("{0}", "PAY4"),
								},
							});
						})
					});
				});
			}).catch((error) => {
				logger.log('[PaymentContent] submitFiservForm startPay error:%o', error);
				this.setState({
					success: false,
					submitting: false,
					type: null,
					errors: {
						...this.state.errors,
						response: this?.props?.messageSettings['general.service_error']?.replace("{0}", "PAY4"),
					},
				});
			});
		})
	}

	getFiservForm() {
		logger.log('[PaymentContent] getFiservForm] fiservRef:%o', this.fiservRef);
															
		return window?.fiserv?.components?.paymentFields({
			hooks: {
			  onCardBrandChange: (cardBrand) => {
				window.document.getElementById("status-cardBrand").innerText = cardBrand ?? "";
			  },
			  onFormValid: () => {
				window.document.getElementById("status-formValid").innerText = "TRUE";
				this.setState({
					fiservFormValid: true
				})
			  },
			  onFormNoLongerValid: () => {
				window.document.getElementById("status-formValid").innerText = "FALSE";
				this.setState({
					fiservFormValid: false
				})
			  },
			  onFieldValidityChange: (fieldState, formState, form) => {
				console.log('[PaymentContent] onFieldValidityChange fieldState: %o, formState:%o, form:%o', fieldState, formState, form )
				let errors = [];
				Object.keys(formState.fields)?.map(key => {
					console.log('[PaymentContent] onFieldValidityChange formState.fields[%o]:%o', key, formState.fields[key] )
					console.log('[PaymentContent] onFieldValidityChange field:%o isValid:%o',formState.fields[key], formState.fields[key]?.hasData && !formState.fields[key]?.isValid && !formState.fields[key]?.isPotentiallyValid && formState.fields[key]?.shouldShowError)
					if (formState.fields[key]?.hasData && !formState.fields[key]?.isValid && !formState.fields[key]?.isPotentiallyValid && formState.fields[key]?.shouldShowError) {
						errors.push(key);
						
					}
				})
				this.setState({
					errors: {
						...this.state.errors,
						fiserv: errors
					},
					fiservFormState: formState
				})
				console.log('[PaymentContent] onFieldValidityChange this.state:%o', this.state);
			  }
			},
			data: {
			  environment: "CERT",
			  paymentMethod: "CREDIT_CARD",
			  supportedCardBrands: this?.props?.paymentConfig?.fiservOptions?.supportedCardBrands,
			  fields: {
				cardNumber: {
				  parentElementId: "field-cardNumber",
				  masking: {
					mode: "ALWAYS_MASK_EXCEPT_TRAILING"
				  }
				},
				nameOnCard: {
				  parentElementId: "field-nameOnCard",
				  placeholder: {
					updateType: "TEXT",
					value: "I AM A PLACEHOLDER"
				  }
				},
				expiration: {
				  parentElementId: "field-expiration"
				},
				securityCode: {
				  parentElementId: "field-securityCode",
				  masking: {
					mode: "ALWAYS_MASK_ALL"
				  }
				}
			  },
			  contextualCssClassNames: {
				invalid: 'invalid',
				valid: 'valid',
			  },
			  css: {
				"input": {
				  "font-size": "16px",
				  color: "#000000",
				  background: 'black',
				},
				".valid": {
				  color: "#006747",
				},
				".invalid": {
				  color: '#ba0c2f',
				  ['border-color']:'#ba0c2f'
				},
				"input::placeholder": {
				  color: "#aaa",
				},
			  }
			}
		})
	}

	/**
	 *  receiveEventsMessage: this function receives the window message so that we can
	 *  process messages from the payment.js that the iframe uses.
	 */
	receiveEventsMessage(message) {
		if (message.data) {
			if (message.data.source == window?.eventsChannel?.name && message.data.type == 'payment') {
				logger.log('[PaymentContent] receiveEventsMessage message:%o', message.data);
				// do something with the message.data.payload.  set some state or something to be used in render
				// set local state with the errors in the payload
				if (message.data.payload.action == 'error') {
					this.setState({
						errors: {
							...this.state.errors,
							payment: message?.data?.payload?.errorCode?.split('|')?.filter(Boolean),
						},
					});
				}

				// set local state because someone clicked the cvv in the iframe.
				if (message.data.payload.action == 'cvv') {
					this.setState(
						{
							showCvv: true,
						},
						() => {
							setTimeout(() => {
								this.setState({ showCvv: false });
							}, 2000);
						}
					);
				}

				// amy:  set local state to handle the confirmation stuff below:
				if (message.data.payload.action == 'success') {
					this.handleSuccess(message.data.payload);
				} else if (message.data.payload.action == 'cancel') {
					this.handleCancel();
				}
			}
		}
	}

	handleSuccess(payload) {
		//let testUrl = "https://ticketsv2-pre.masters.com/tickets/services/application/pay/masters/2023?transId=61FC6C3724A98D690860A005BDC0AD901EA754A3&appCode=095366&name=Mitch%20Tester&paymentType=CC&ccNumber=XXXXXXXXXXXX1111&expMonth=01&expYear=2025&ccType=Visa&cardBrandSelected=Visa&CVVMatch=M&AVSMatch=H%20&profileId=cre71407575SB&orderId=0X0X2023"

		let url = this.props.ticketsData.pay;

		if (payload && this.props.userData.application.amtDue > 0) {

			let data = payload.data;
			logger.log('[PaymentContent] handleSuccess data:%o', data);

			Object.keys(data).map(key => {
				url += this.props.paymentConfig.payOptions.indexOf(key) !== -1 ? `${key}=${data[key]}&` : '';
			});
		} else {
			url += `amount=${this.props.userData.application.amtDue}&`;
		}

		url += `orderId=${this.getOrderId()}`;

		logger.log('[PaymentContent] handleSuccess url:%o', url);

		axios({
			method: 'get',
			url: url,
			headers: { Authorization: `Bearer ${this.props.userData.token}` },
		})
			.then(payRes => {
				logger.log('[PaymentContent] payRes:%o', payRes);
				if (payRes.status == '200') {
					if (payRes?.data?.applicationStatus == 3) {
						this.setState(
							{
								success: true,
								type: 'payment',
								errors: {}
							},
							() => {
								logger.log('[PaymentContent] handleSuccess after application.pay:%o', payRes.data);

								this.refreshInterval = setInterval(() => {
									if (this.props.userData?.application?.applicationStatus === 3) {
										// Stop the interval once the condition is met (applicationStatusId === 3).
										clearInterval(this.refreshInterval);
									  } else {
										// Call the refreshProfile function if the condition is not met.
										this.props.refreshProfile();
									  }
								}, 2000);

								//this.props.refreshProfile();
							}
						);
					} else {
						logger.log('[PaymentContent] handleSuccess could not validate payment');
						this.setState({
							success: false,
							type: null,
							errors: {
								...this.state.errors,
								response: this?.props?.messageSettings['general.service_error']?.replace("{0}", "PAY3"),
							},
						});
					}
				} else {
					logger.log('[PaymentContent] handleSuccess payRes.status:%o', payRes.status);
					//throw new Error(payRes.status);
					this.setState({
						success: false,
						type: null,
						errors: {
							...this.state.errors,
							response: this?.props?.messageSettings['general.service_error']?.replace("{0}", "PAY4"),
						},
					});
				}
			})
			.catch(error => {
				logger.log('[PaymentContent] handleSuccess error getting application error:%o', error);

				this.setState({
					success: false,
					type: null,
					errors: {
						...this.state.errors,
						response: this?.props?.messageSettings['general.service_error']?.replace("{0}", "PAY5"),
					},
				});
				//return error;
			});
	}

	handleCancel() {
		if (!this.state.success) {
			this.setState({
				success: true,
				type: 'cancel',
			});
		}
	}

	renderError(errorCode, index) {
		//let message = op.get(this.props, `paymentMessages.payment.${errorCode}.message`);
		let message = this.props.messageSettings[`payment.error_${errorCode}`];

		if (message) {
			return renderErrorItem(message, index);
		} else {
			return renderErrorItem(`${this.props.messageSettings[`payment.error_general`]} (${errorCode})`,index);
		}
	}

	renderFiservError(error) {
		return renderErrorItem(`${error} is invalid`);
	}

	transformUrl(url) {
		let tempUrl = url;
		let timestamp = Date.now();
		let merchPass = this?.props?.messageSettings['system.controlscan_id'];
		let application = Object.assign({},this?.state?.application);
		application.savCustId = application?.savCustId ? application?.savCustId : "0";
		application.savCustAppId = application?.savCustAppId ? application?.savCustAppId : "0";

		if (tempUrl) {
			// replace fields in url with corresponding fields in userData

			let encodedUserData = {};
			Object.keys(this.props.userData).map(key => {
				logger.log('[PaymentContent] - transformUrl key:%o', key);
				
				if (key !== 'savCustId' && key !== 'savCustAppId') {
					encodedUserData[key] = encodeURIComponent(
						null !== this?.props?.userData[key] &&
							undefined !== this?.props?.userData[key] &&
							this?.props?.userData[key]
							? typeof this.props.userData[key] == "string" 
								? this.props.userData[key]?.replace(/[\u2018\u2019\u0091\u0092]/g, "'")?.replace(/[\u201C\u201D]/g, '"')
								: this?.props?.userData[key]
							: ''
					);
				}
			});
			//let newUrl = replaceAll(tempUrl, this.props.userData);
			let newUrl = replaceAll(tempUrl, encodedUserData);

			newUrl = replaceAll(newUrl, application);
			newUrl = newUrl.replace('<timestamp>', timestamp);
			newUrl = newUrl.replace('<merchPass>', merchPass);

			logger.log('[PaymentContent] - transformUrl newUrl:%o', newUrl);

			// save the sessionId so that it can be used later when calling pay endpoint
			// this.setState({
			// 	sessionId: `${this.props.userData.patronId}_${timestamp}`,
			// });
			this.sessionId = `${this.props.userData.patronId}_${timestamp}`;
			return newUrl;
		} else {
			logger.error(`url not defined`);
			return false;
		}
	}

	getIframeSrc(application) {
		//let src = this.props.paymentConfig.iframe;
		let src = `${this?.props?.messageSettings['system.controlscan_url']}?`;
		// loop thru the options in the payment config and construct the iframe src.
		Object.keys(this.props.paymentConfig.options).map((option, i) => {
			src += `${option}=${this.props.paymentConfig.options[option]}${
				i !== Object.keys(this.props.paymentConfig.options).length - 1 ? '&' : ''
			}`;
		});

		src = this.transformUrl(src);

		logger.log('[PaymentContent] - getIframeSrc src:%o', src);

		return src;
	}

	getOrderId() {
		let { orderId } = this.props.paymentConfig.options;
		logger.log('[PaymentContent] getOrderId orderId:%o', orderId);

		let application = Object.assign({},this?.state?.application);
		let userData = Object.assign({},this?.props?.userData);
		application.savCustId = application?.savCustId ? application?.savCustId : "0";
		application.savCustAppId = application?.savCustAppId ? application?.savCustAppId : "0";
		userData.savCustId = op.get(userData, 'savCustId', "0");

		logger.log('[PaymentContent] getOrderId application:%o', application);

		orderId = replaceAll(orderId, application);
		orderId = replaceAll(orderId, userData);

		logger.log('[PaymentContent] getOrderId return orderId:%o', orderId);

		return orderId;
	}



	render() {
		logger.log('[PaymentContent] render this:%o', this);

		let action = op.get(this.props, 'Router.params.action', false);
		let applicationPhase = getApplicationPhase(op.get(this.props, 'userData.application'), this.props.eventConfig);
		let paymentSteps = getPaymentSteps(this?.props?.userData, this?.props?.messageSettings?.['system.mobile_verification'], this?.props?.eventConfig, this?.props?.requiresVerifyId);

		let script = this?.props?.paymentConfig?.paymentType == 'cre' ? "/assets/static/payment/payment.js" :this?.props?.paymentConfig?.fiserv_sdk;
		//let script = this?.props?.legacyPayments ? "/assets/static/payment/payment.js" : "https://commercehub-secure-data-capture.fiservapps.com/3.1.9/checkout.js"
		//logger.log('[PaymentContent] render applicationPhase:%o', applicationPhase);

		let fieldClassnames = (fieldName) => {
			logger.log('[PaymentContent] render fieldClassnames fieldName:%o', fieldName, this?.state?.errors?.fiserv);

			return cn({
				field: true,
				invalid: this?.state?.errors?.fiserv?.includes(fieldName),
				valid: this?.state?.fiservFormState?.fields?.[fieldName]?.isValid
			});
		};

		if (this?.props?.paymentConfig?.paymentType) {
			return (
				<Template script={script}>
					{(this?.state?.errors?.application || this.props?.userData?.addressValidationStatusId == 12 || (this?.props?.eventConfigStatus?.status == 'loaded' && !this?.props?.eventConfig?.allowPayment) || !this?.props?.userData?.application?.hasAcceptedTicketAgreement  || (this?.props?.userData?.eventName == 'psb' && !this?.props?.userData?.application?.dateJrPassAgreementResponse)) ? (
					//{this?.state?.errors?.application || this.props?.userData?.addressValidationStatusId == 12 || (this?.props?.eventConfigStatus?.status == 'loaded' && !this?.props?.eventConfig?.allowPayment) ? (
						<Redirect push to={this.props?.flowData?.actions?.general?.logout} />
					) : null}
					{this.state.isReady && 
					this.state.application &&
					this.props.staticDataStatus == 'loaded'  ? (
						<section id="payment-page" className="page-content">
							<section className="page-title">
								<h1>{this.props.pageTitle}</h1>
							</section>
							<section className="hero">
								<ContentHero />
							</section>
							{!this.state.success ? (
								<>
									{
										this.props.flowData && this.state.timeRemaining &&  this?.state?.application?.amtDue > 0 ? (
											<>
												<section className="section-title-wrapper">
													<h2>Payment</h2>
													<StepIndicator
														type="winner"
														showWinnerSplash={false}
														stepLabels={this?.props?.messageSettings?.[paymentSteps?.id]}
														currentStep={paymentSteps?.currentStep}
														/>  
												</section>
												<section className="page-grid cols-1">
												<div className="page-grid-item">
													
													<CmsMessageContent id="payment.coa_payment_instructions" />
												
													<section className="countdown-wrapper">Time remaining to complete transaction: <span className="countdown-timer">{`${this.state.timeRemaining.minutes}:${this.state.timeRemaining.seconds}`}</span></section>

													<AccountInfo
														category={this.props.Router.params.category}
														userData={this.props.userData}
														eventData={this.props.eventConfig}
														action={action}
														editable={this.state.editable}
													/>
													<div className="ticket-info">
														<div className="header">
															<h3>
																<CmsMessageContent id="application.header" textOnly={true} />
															</h3>
														</div>
														{this.state.pInvoice && this.state.pInvoice.length > 0 ? (
															<TicketApplication
																data={this.state.pInvoice}
																title={
																	this.props.userData.eventName !== 'psb'
																	? this.props.userData.eventName == 'masters' ? 'Practice Round Tickets': 'Tournament Tickets'
																	: ''
																}
																// subTitle={
																// 	this.props.userData.eventName !== 'psb'
																// 		? `$${parseFloat(this.props.eventConfig.day1Price).toFixed(
																// 				2
																// 		  )} each`
																// 		: `Cost per Badge: $${this.props.messageSettings['system.series_price']} each`
																// }
																highlight="TOTALS"
															/>
														) : null}

														{this.state.tInvoice && this.state.tInvoice.length > 0 ? (
															<TicketApplication
																data={this.state.tInvoice}
																title="Tournament Round Tickets"
																// subTitle={`$${parseFloat(this.props.eventConfig.day4Price).toFixed(
																// 	2
																// )} each`}
																highlight="TOTALS"
															/>
														) : null}

														<div className="total">
															Amount Due: ${parseFloat(this.state.application.amtDue).toFixed(2)}
														</div>

														<div className="card-header">
															<div>Credit Card Information</div>
														</div>
														<div>
															<img
																className="card-icons"
																src="/assets/images/payment/mastercard.svg"
															/>
															<img className="card-icons" src="/assets/images/payment/amex.svg" />
															<img className="card-icons" src="/assets/images/payment/visa.svg" />
															<img className="card-icons" src="/assets/images/payment/discover.svg" />
														</div>

														{this.state.errors.payment.length > 0 ? (
															<div className="form-content">
																<div className="error-field">
																	{this.state.errors.payment.map((errorCode, i) => {
																		// render an error for each code
																		return this.renderError(errorCode, i);
																	})}
																</div>
															</div>
														) : null}

														{this?.state?.errors?.fiserv?.length > 0 ? (
															<div className="form-content">
																<div className="error-field">
																	{this?.state?.errors?.fiserv?.map((error, i) => {
																		// render an error for each code
																		return this.renderFiservError(error);
																	})}
																</div>
															</div>
														) : null}

														{this.state.errors.response ? (
															<div className="form-content">
																<div className="error-field">
																	{renderErrorItem(this.state.errors.response, 0)}
																</div>
															</div>
														) : null}

														{this.state.showCvv ? (
															<div id="tooltip-cvv" className="tooltip">
																<span className="tooltiptext">
																	{this.props.messageSettings[`payment.info_cvv`]}
																</span>
															</div>
														) : null}
														{/* If using legacy payment system */}
														{this?.props?.paymentConfig?.paymentType == 'cre' 
															? <iframe id="payment-container" src={this.state.iframeSrc}></iframe>
															: (
																<div id="fiserv" ref={this.fiservRef}>
																	FISERV
																	<span>Form Valid: </span>
																	<span id="status-formValid">FALSE</span>
																	<br />
																	<span>Card Brand: </span>
																	<span id="status-cardBrand"></span>
																	<br />
																	<br />
																	<div id="fiservForm">
																		<div id="wrapper-nameOnCard" className="field-wrapper">
																			<label htmlFor="field-nameOnCard">Name on Card</label>
																			<div id="field-nameOnCard" className={fieldClassnames("nameOnCard")}></div>
																			
																		</div>
																		<div id="wrapper-amount" className="field-wrapper">
																			<label htmlFor="field-amount">Amount</label>
																			<div id="field-amount" className={fieldClassnames("amount")}>
																				<input 
																					type="text" 
																					aria-label="Amount" 
																					aria-required="false" 
																					aria-invalid="false" 
																					name="amount" 
																					id="amount" maxLength="100" 
																					placeholder={parseFloat(this.state.application.amtDue).toFixed(2)}  
																					autoCapitalize="none" 
																					spellCheck="false" 
																					disabled 
																					aria-disabled="true" />
																			</div>
																		</div>
																		<div id="wrapper-cardNumber" className="field-wrapper">
																			<label htmlFor="field-cardNumber">Card Number</label>
																			<div id="field-cardNumber" className={fieldClassnames("cardNumber")}></div>
																		</div>
																		<div id="wrapper-expiration" className="field-wrapper">
																			<label htmlFor="field-expiration">Expiration</label>
																			<div id="field-expiration" className={fieldClassnames("expiration")}></div>
																		</div>
																		<div id="wrapper-securityCode" className="field-wrapper">
																			<label htmlFor="field-securityCode">Security Code</label>
																			<div id="field-securityCode" className={fieldClassnames("securityCode")}></div>
																		</div>
														
																	</div>
																	<div className="page-actions">
																		<Button
																			style={this.props.numericWindowSize < 3 ? 'full' : ''}
																			//buttonState={(!formProps.dirty && isEmpty(formProps.touched)) || !formProps.isValid ? 'disabled' : ''}
																			buttonState={this.state.submitting || !this.state.fiservFormValid ? 'disabled' : ''}

																			onClick={() => {
																				if (this.state.fiservFormValid) {
																					this.submitFiservForm();
																				}
																			}}
																			buttonText="Submit" />

																			<div className="action-link-container">
																				{/* <EventsLink style="action-link" onClick={() => this.handleCancel()}>
																					Cancel
																				</EventsLink> */}
																				<EventsLink style="action-link"  onClick={() => this.handleCancel()}>
																					Cancel
																				</EventsLink>
																				{/* <EventsLink style="action-link" to={op.get(this.props, 'flowData.actions.addressConfirmation.cancel', '')}>
																					Cancel
																				</EventsLink> */}
																			</div>
																	</div>
																</div>
																
															)
														}
													</div>
												</div>
											</section>
											</>
										) : (
											<section className="page-grid cols-1">
												<div className="page-grid-item">
													<h4 style={{"textAlign":"center"}}>
														{this?.state?.application?.amtDue > 0 ? 'Please wait while we load order details.' : 'Please wait while we process your order.'}
													</h4>
													<section className='page-content' style={{"position":"relative","marginTop":"20px"}}>
														<LoadingIndicator />
													</section>
													
												</div>
											</section>
										)
									}
								</>
							) : (
								<>
									{this.state.type == 'payment' && applicationPhase == "applicationPaid" ? (
										<Redirect push to={this.props?.flowData?.actions?.payment?.submit} />
									) : (
										<section className="page-grid cols-1">
											<div className="page-grid-item">
												<h4 style={{"textAlign":"center"}}>
													{'Please wait while we process your order.'}
												</h4>
												<section className='page-content' style={{"position":"relative","marginTop":"20px"}}>
													<LoadingIndicator />
												</section>
												
											</div>
										</section>
									)}
									{this.state.type == 'cancel' ? (
										<Redirect push to={this.props?.flowData?.login?.unpaidWinnerHome} />
									) : null}
								</>
							)}
						</section>
					) : (
						<section id="payment-page" className="page-content">
							<section className="page-title">
								<h1>{this.props.pageTitle}</h1>
							</section>
							<section className="hero">
								<ContentHero />
							</section>
							<section className="page-grid cols-1">
								<div className="page-grid-item">
									<h4 style={{"textAlign":"center"}}>Please wait while we load details</h4>
									<section className='page-content' style={{"position":"relative","marginTop":"20px"}}>
										<LoadingIndicator />
									</section>
									
								</div>
							</section>
						</section>
					)}
				</Template>
			);
		} else {
			return null;
		}
	}
}

PaymentContent.defaultProps = {};

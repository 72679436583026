/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import { Redirect } from 'react-router';
 
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { writeErrors } from 'shared/forms/elements/Utils';
import { FormCheckBox, FormRadioButton } from 'shared/forms/elements/FormFields';
import Button from 'shared/general/Button';
import CmsMessageContent from 'shared/general/CmsMessageContent';
import EventsLink from 'shared/general/EventsLink';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import LoadingIndicator from 'shared/general/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import { values } from 'lodash';

 
/**
 * -----------------------------------------------------------------------------
 * Functional Component: const JuniorPassForm = (props) => {
 * -----------------------------------------------------------------------------
 */
 const mapStateToProps = (state, props) => {
	return {
		...props,
        userData: op.get(state['UserData'], 'userData', {}),
        messageSettings: op.get(state['Config'], 'staticData.cmsMessages.data'),
        flowData: op.get(state['Config'], 'staticData.flow.data', {}),
	};
};

const mapDispatchToProps = dispatch => ({
    updateUserData: data => dispatch(deps.actions.UserData.updateUserData(data)),
	updateTempUserData: () => dispatch(deps.actions.UserData.updateTempUserData()),
	updateApplicationData: data => dispatch(deps.actions.UserData.updateApplicationData(data)),
    loadConfig: () => dispatch(deps.actions.Config.loadConfig()),
});

class JuniorPassForm extends Component {
    constructor(props) {
		super(props);
		this.state = {
            success: false,
            JuniorPassValues : {
                "jrPassIntendToUse": !this?.props?.userData?.application?.jrPassAgreement ? null : this?.props?.userData?.application?.jrPassIntendToUse.toString(), 
                "jrPassThu":this?.props?.userData?.application?.jrPassThu,
                "jrPassFri": this?.props?.userData?.application?.jrPassFri,
                "jrPassSat":this?.props?.userData?.application?.jrPassSat,
                "jrPassSun":this?.props?.userData?.application?.jrPassSun,            
            }
        };

        this.loadingRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.submit = false;

        logger.log('[JuniorPassForm] props:%o', props);
	}

    componentWillUnmount() {
        this.submit = false;

        this.setState({
            success: false
        }) 
    }

    componentDidMount() {
		logger.log('[JuniorPass] - componentDidMount this%o', this);
		if (this.props.siteConfigStatus == 'loaded') {
			this.setState({
				mounted: true,
			});
		} else {
			this.props.loadConfig();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[JuniorPass] - componentDidUpdate props%o', this.props);
		if (prevProps.siteConfigStatus !== this.props.siteConfigStatus && this.props.siteConfigStatus == 'loaded') {
			this.setState({
				mounted: true,
			});
		}

		if (prevState.mounted !== this.state.mounted) {
			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				pageTitle: this.getMeasurementTitle(),
				userID: this.props.userData.userID,
				site: this.props?.site,
			});
		}

        // if((!isEqual(prevProps.userData, this.props.userData) ||
        //     isEqual(prevProps.userData, this.props.userData)) && 
        //     this.submit === true
        // ) {
        //     this.submit = false;

        //     this.setState({
        //         success: true
        //     })
        // }
	}

	componentDidUpdate(prevProps) {

    }

    handleSubmit = (values) => {
        logger.log('[JuniorPassForm] handleSubmit - this:%o', this);
        logger.log('[JuniorPassForm] handleSubmit - values:%o', values);

        /** show loading indicator while everyone is working hard to create an account */
		if (this.loadingRef.current) {
			this.loadingRef.current.classList.add('show');
		}
        this.submit = true;
        let data = values;
        data["jrPassIntendToUse"] = values["jrPassIntendToUse"] == "true" ? true : false;
        data["jrPassAgreement"] = true;

        logger.log('[JuniorPassForm] handleSubmit - data:%o', data);

        
        // this.props.updatePatronData(this.props.userData)
        // this.props.updateApplicationData(data);


        this.props.updateApplicationData(data).then(appResponse => {
            if (appResponse.status == 200) {
                logger.log('[JuniorPassForm] handleSubmit appResponse:%o', appResponse);
                this.setState({
                    ...this.state,
                    success: true,
                    errors: {}
                }, () => {
                    this.submit = false;
                });
            } else {
                logger.log('[JuniorPassForm] handleSubmit error appResponse:%o', appResponse);
                this.setState({
                    ...this.state,
                    success: false,
                    errors: {
                        response: `${appResponse.data.errorCode}`,
                    },
                }, () => {
                    this.submit = false;
                });
            }
        });
    }

    handleChange = (fieldName, formProps) => {
        let {values} = formProps;
        let errors = {}
        let JuniorPassValues = {
            ...values,
            "jrPassThu":false,
            "jrPassFri":false,
            "jrPassSat":false,
            "jrPassSun":false,
            "jrPassIntendToUse":values[fieldName] == "true" ? "false" : "true"
        }

        logger.log('[JuniorPassForm] handleChange fieldName:%o, values:%o', fieldName, values);

        if (fieldName !== "jrPassIntendToUse") {
           JuniorPassValues = {
                ...values,
                [fieldName]:!values[fieldName],
                "jrPassIntendToUse":!values[fieldName] == true ? "true" : values["jrPassIntendToUse"]
            }
            if (!this.isFormValid({values:JuniorPassValues})) {
                errors['selectDay'] = "You must select at least one day"
            }
            this.setState({
                errors,
                JuniorPassValues
            }, () => {
                //logger.log('[JuniorPassForm] handleChange valid:%o', this.isFormValid({values:JuniorPassValues}));

                MeasurementUtils.dispatchMeasurementCall("JuniorPass", {
                    site: this.props.userData.eventName,
                    id: fieldName,
                    checked: this.state.JuniorPassValues[fieldName]
                });
            })
        } else {
            // if (null == values[fieldName] || "false" == values[fieldName] ) {
            //     errors['selectDay'] = "You must select at least one day"
            // }
            this.setState({
                errors,
                JuniorPassValues, //: {
                    // ...values,
                    // "jrPassThu":false,
                    // "jrPassFri":false,
                    // "jrPassSat":false,
                    // "jrPassSun":false,
                    // "jrPassIntendToUse":values[fieldName] == "true" ? "false" : "true"
                    //}
            }, () => {
                MeasurementUtils.dispatchMeasurementCall("JuniorPass", {
                    site: this.props.userData.eventName,
                    id: fieldName,
                    checked: this.state.JuniorPassValues[fieldName]
                });
            })
        }
    }

    isFormValid = (formProps) => {
        let valid = false; 
        let {values} = formProps;
        logger.log('[JuniorPassForm] isFormValid - values:%o', values);

        if (values.jrPassIntendToUse == "true" || values.jrPassIntendToUse == true) {
            valid = true;
            // if (values.jrPassThu || values.jrPassFri || values.jrPassSat || values.jrPassSun) {
            //     valid =  true;
            // }
        } else if (values.jrPassIntendToUse == "false" || values.jrPassIntendToUse == false) {
            valid =  true;
        }
        
        logger.log('[JuniorPassForm] isFormValid - valid:%o',valid);

        return valid
    }

    getLoadingIndicator = () => {
		return (
			<div className="fadeOverlay" ref={this.loadingRef}>
				<LoadingIndicator />
			</div>
		);
	};
 
    render() {
        logger.log('[JuniorPassForm] render - this:%o', this);

        let JuniorPassSchema = yup.object({
            "jrPassIntendToUse":yup.boolean().required().nullable().oneOf([false , true]), 
            "jrPassThu":yup.boolean(),
            "jrPassFri": yup.boolean(),
            "jrPassSat":yup.boolean(),
            "jrPassSun":yup.boolean(),  
        });

        if (this.state.success === true && this?.props?.userData?.application?.applicationStatus == 3 && this?.props?.flowData.actions.jrPass.submit) {
            return <Redirect push to={this?.props?.flowData?.actions?.jrPass?.submit} />;
		} else if (this.state.success === true && this?.props?.userData?.application?.applicationStatus == 2 && this?.props?.flowData.actions.jrPass.submitUnpaid) {
            return <Redirect push to={this?.props?.flowData?.actions?.jrPass?.submitUnpaid} />;
        }

        return (
            <div className="form-container junior-pass">
				<div className="form-content">
					<div className="junior-pass-container">

						<Formik
							initialValues={this.state.JuniorPassValues}
							validationSchema={JuniorPassSchema}
							onSubmit={this.handleSubmit}
							enableReinitialize={true}
                            validateOnChange={true}
							key="juniorPass">
							{(formProps) => {
								logger.log('[JuniorPassForm] render - formProps:%o', formProps);

                                //this.isFormValid(formProps);
                                // let formValid = this.isFormValid(formProps);
                                // formProps.errors = formValid.errors;
                                // let isValid = formValid.valid;

								return (
									<Form id="juniorPassForm">
										{writeErrors(formProps, this.state.errors)}
                                        <div>
                                            <FormRadioButton name="jrPassIntendToUse" id="jrPassIntendToUseY" value={"true"} checked={this.state?.JuniorPassValues?.jrPassIntendToUse == "true"} onChange={() => this.handleChange("jrPassIntendToUse", formProps)}>
                                                <CmsMessageContent id="payment.jr_pass_yes" textOnly={true} />
                                            </FormRadioButton>
                                            <div className="checkbox-group-container">
                                                <FormCheckBox name="jrPassThu" id="jrPassThu" onChange={() => this.handleChange("jrPassThu", formProps)}>
                                                    <CmsMessageContent id="payment.jr_pass_day_1" textOnly={true} />
                                                </FormCheckBox>
                                                <FormCheckBox name="jrPassFri" id="jrPassFri" onChange={() => this.handleChange("jrPassFri", formProps)}>
                                                    <CmsMessageContent id="payment.jr_pass_day_2" textOnly={true} />
                                                </FormCheckBox>
                                                <FormCheckBox name="jrPassSat" id="jrPassSat" onChange={() => this.handleChange("jrPassSat", formProps)}>
                                                    <CmsMessageContent id="payment.jr_pass_day_3" textOnly={true} />
                                                </FormCheckBox>
                                                <FormCheckBox name="jrPassSun" id="jrPassSun" onChange={() => this.handleChange("jrPassSun", formProps)}>
                                                    <CmsMessageContent id="payment.jr_pass_day_4" textOnly={true} />
                                                </FormCheckBox>
                                            </div>
                                            <FormRadioButton name="jrPassIntendToUse" id="jrPassIntendToUseN" value={"false"} checked={this.state?.JuniorPassValues?.jrPassIntendToUse == "false"} onChange={() => this.handleChange("jrPassIntendToUse", formProps)}>
                                                <CmsMessageContent id="payment.jr_pass_no" textOnly={true} /> 
                                            </FormRadioButton>
                                        </div>

                                        
                                        <div className="page-actions">
                                            <Button
                                                style='full'
                                                buttonState={!this.isFormValid(formProps) ? 'disabled' : ''}
                                                onClick={() => {
                                                    if (formProps.isValid) {
                                                        formProps.submitForm();
                                                    }
                                                }}
                                                buttonText={this?.props?.userData?.application?.applicationStatus == 3 ? 'Submit' : 'Next'} />
                                            
                                            <div className="action-link-container">
                                                <EventsLink style="action-link" to={this?.props?.userData?.application?.applicationStatus == 3 ? this?.props?.flowData?.actions?.jrPass?.cancel : this?.props?.flowData?.actions?.jrPass?.cancelUnpaid}>
                                                    Cancel
                                                </EventsLink>
                                            </div>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
                {this.getLoadingIndicator()}
            </div>
        );
    }
};
 
export default connect(mapStateToProps, mapDispatchToProps)(JuniorPassForm);
 